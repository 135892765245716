import { readonly } from 'vue'
import { ActionScope } from '@/api/useActions.types'
import { AssessmentAPI } from '@/api/useAssessments.types'
import { ActionScopeSubscope } from '../composable/useActionGroups.types'

export interface ScopeSubscope {
  scope: ActionScope
  subscope: number | null
  name: string
}

export const scopeSubscopes = readonly<ScopeSubscope[]>([
  { scope: ActionScope.First, subscope: null, name: 'general' },
  { scope: ActionScope.First, subscope: 1, name: 'stationaryCombustion' },
  { scope: ActionScope.First, subscope: 2, name: 'mobileCombustion' },
  { scope: ActionScope.First, subscope: 3, name: 'fugitiveEmissions' },
  { scope: ActionScope.First, subscope: 4, name: 'processEmissions' },
  { scope: ActionScope.Second, subscope: null, name: 'general' },
  { scope: ActionScope.Second, subscope: 1, name: 'purchasedElectricity' },
  { scope: ActionScope.Second, subscope: 2, name: 'purchasedSteam' },
  { scope: ActionScope.Second, subscope: 3, name: 'purchasedHeating' },
  { scope: ActionScope.Second, subscope: 4, name: 'purchasedCooling' },
  { scope: ActionScope.Third, subscope: null, name: 'general' },
  { scope: ActionScope.Third, subscope: 1, name: 'purchasedGoodsAndServices' },
  { scope: ActionScope.Third, subscope: 2, name: 'capitalGoods' },
  {
    scope: ActionScope.Third,
    subscope: 3,
    name: 'fuelAndEnergyRelatedActivities',
  },
  {
    scope: ActionScope.Third,
    subscope: 4,
    name: 'upstreamTransportationAndDistribution',
  },
  { scope: ActionScope.Third, subscope: 5, name: 'wasteGeneratedInOperations' },
  { scope: ActionScope.Third, subscope: 6, name: 'businessTravel' },
  { scope: ActionScope.Third, subscope: 7, name: 'employeeCommuting' },
  { scope: ActionScope.Third, subscope: 8, name: 'upstreamLeasedAssets' },
  {
    scope: ActionScope.Third,
    subscope: 9,
    name: 'downstreamTransportationAndDistribution',
  },
  { scope: ActionScope.Third, subscope: 10, name: 'processingOfSoldProducts' },
  { scope: ActionScope.Third, subscope: 11, name: 'useOfSoldProducts' },
  {
    scope: ActionScope.Third,
    subscope: 12,
    name: 'endOfLifeTreatmentOfSoldProducts',
  },
  { scope: ActionScope.Third, subscope: 13, name: 'downstreamLeasedAssets' },
  { scope: ActionScope.Third, subscope: 14, name: 'franchises' },
  { scope: ActionScope.Third, subscope: 15, name: 'investments' },
])

export const scopeSubscopeToI18nKey = (scopeSubscope: ActionScopeSubscope) => {
  const entry = scopeSubscopes.find((e) =>
    isEqualScopeSubscope(e, scopeSubscope)
  )
  return `global.assessmentForm.${entry?.name ?? 'general'}`
}

export const stringifyScopeSubscope = ({
  scope,
  subscope,
}: ActionScopeSubscope): string => {
  return subscope ? `${scope}.${subscope}` : `${scope}`
}

export const isEqualScopeSubscope = (
  a: ActionScopeSubscope | null,
  b: ActionScopeSubscope | null
) => {
  return a?.scope === b?.scope && a?.subscope === b?.subscope
}

export const getScopeSubscopeEmission = (
  scopeSubscope: ActionScopeSubscope,
  assessment: AssessmentAPI | undefined
) => {
  const emissionsInSubscope = (assessment?.emissions ?? []).find((a) =>
    isEqualScopeSubscope(a, scopeSubscope)
  )

  return emissionsInSubscope?.value ?? 0
}
